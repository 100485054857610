// @ts-check
import Vue from 'vue'
// @ts-ignore
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/store'
// import VueTween from '@seregpie/vuetween'
import Http from './plugins/http'
import i18n from './plugins/i18n'
import iconsService from './plugins/icons-service'
import utils from './plugins/utils'
import router from './router'
import lodash from 'lodash'
import Account from './plugins/account'
import { v4 as uuid } from 'uuid'
import CKEditor from 'ckeditor4-vue'
// import CacheType from '@/wasm/pkg'
// import VueHotkey from 'v-hotkey'

import VueKeepScrollPosition from 'vue-keep-scroll-position'

Vue.config.productionTip = false

Object.defineProperty(Vue.prototype, 'lodash', { value: lodash })

/* Patch Marco
Keep-alive and component $destroy()
If we dont call this method after a $destroy(), the component still in cache and cause component duplication for the same key.
*/
Vue.prototype.$removeFromKeepAliveCache = function () {
  if (!this.$vnode.parent) { return }
  const key = this.$vnode.key
  const cache = this.$vnode.parent.componentInstance.cache
  const keys = this.$vnode.parent.componentInstance.keys

  delete cache[key]
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === key) { keys.splice(i, 1) }
  }
}

// Do not use an arrow function as it seem  to cause issues with Apexcharts
Vue.prototype.$triggerResize = function () {
  window.dispatchEvent(new Event('resize'))
}

Vue.prototype.$waitFor = (something, timeout = 10000) => {
  if (something.constructor.name !== 'Function') {
    throw new Error('$waitFor take a function as a parameter.')
  }
  return new Promise(resolve => {
    let timedOut = false
    const timeoutHandler = setTimeout(() => { timedOut = true }, timeout)
    const intervalHandler = setInterval(() => {
      const result = something()
      if (result || timedOut) {
        clearTimeout(timeoutHandler)
        clearInterval(intervalHandler)
        resolve(result)
      }
    }, 100)
  })
}

(async () => {
  const { CacheType } = await import('@/wasm/pkg')
  Vue.prototype.$cacheTypeFromString = (typeString) => {
    switch (typeString) {
      case 'user-accounts': return CacheType.UserAccountRef
      case 'account-contacts': return CacheType.UserRef
      case 'accounts': return CacheType.AccountRef
      case 'aging-bucket-range-ref': return CacheType.AgingBucketRangeRef
      case 'aging-buckets': return CacheType.AgingBucketRef
      case 'aging-scenarios': return CacheType.AgingScenarioRef
      case 'allocation-announcements': return CacheType.AllocationAnnouncementRef
      case 'announcement-origins': return CacheType.AnnouncementOriginRef
      case 'announcement-statuses': return CacheType.AnnouncementStatusRef
      case 'ar24-message-statuses': return CacheType.Ar24MessageStatusRef
      case 'auto-filters': return CacheType.DocumentTypeRef
      case 'business-divisions': return CacheType.BusinessDivisionRef
      case 'calendar-based-reminder-protocols': return CacheType.CalendarBasedReminderProtocolRef
      case 'calendar-based-reminder-statuses': return CacheType.CalendarBasedReminderStatusRef
      case 'calendar-based-reminders': return CacheType.CalendarBasedRemindersRef
      case 'collaboration-task-types': return CacheType.CollaborationTaskTypeRef
      case 'collaboration-tasks': return CacheType.CollaborationTaskRef
      case 'collection-segments': return CacheType.CollectionSegmentRef
      case 'column-sets': return CacheType.ColumnSetRef
      case 'columns': return CacheType.ColumnRef
      case 'contact-groups': return CacheType.ContactGroupRef
      case 'credit-hold-reasons': return CacheType.CreditHoldReasonRef
      case 'credit-statuses': return CacheType.CreditStatusRef
      case 'credit-status-groupamas': return CacheType.CreditStatusGroupamaRef
      case 'cultures': return CacheType.CultureRef
      case 'currencies': return CacheType.CurrencyRef
      case 'dispute-reason-categories': return CacheType.DisputeReasonCategoryRef
      case 'dispute-reasons': return CacheType.DisputeReasonRef
      case 'disputes': return CacheType.DisputeRef
      case 'dispute-invoices': return CacheType.DisputeInvoiceRef
      case 'document-types': return CacheType.DocumentTypeRef
      case 'dunning-reminder-statuses': return CacheType.DunningReminderStatusRef
      case 'dunning-reminders': return CacheType.DunningRemindersRef
      case 'dunning-runs': return CacheType.DunningRunRef
      case 'escalation-protocol-levels': return CacheType.EscalationProtocolLevelRef
      case 'escalation-protocols': return CacheType.EscalationProtocolRef
      case 'fields': return CacheType.FieldRef
      case 'files': return CacheType.FileRef
      case 'group-contributors': return CacheType.GroupContributorRef
      case 'groupama-decision-codes': return CacheType.GroupamaDecisionCodeRef
      case 'groupama-requests': return CacheType.GroupamaRequestRef
      case 'groups': return CacheType.GroupRef
      case 'import-data-sources': return CacheType.ImportDataSourceRef
      case 'invoice-statuses': return CacheType.InvoiceStatusRef
      case 'invoices': return CacheType.InvoiceRef
      case 'label-groups': return CacheType.LabelGroupRef
      case 'labels': return CacheType.LabelRef
      case 'main-presets': return CacheType.MainPresetRef
      case 'maileva-letter-statuses': return CacheType.MailevaLetterStatusRef
      case 'message-priorities': return CacheType.MessagePriorityRef
      case 'messages-inbox': return CacheType.MessageInboxRef
      case 'messages-outbox': return CacheType.MessageOutboxRef
      case 'messages-unassigned': return CacheType.MessagesUnassignedRef
      case 'portal-payment-statuses': return CacheType.PortalPaymentStatusRef
      case 'portal-statuses': return CacheType.PortalStatusRef
      case 'processes': return CacheType.ProcessRef
      case 'promise-invoices': return CacheType.PromiseInvoiceRef
      case 'promise-installments': return CacheType.PromiseInstallmentRef
      case 'promise-statuses': return CacheType.PromiseStatusRef
      case 'promises': return CacheType.PromiseRef
      case 'providers': return CacheType.ProviderRef
      case 'provider-types': return CacheType.ProviderTypeRef
      case 'relative-currencies': return CacheType.RelativeCurrencyRef
      case 'reminder-runs': return CacheType.ReminderRunRef
      case 'reminder-types': return CacheType.ReminderTypeRef
      case 'risk-categories': return CacheType.RiskCategoryRef
      case 'roles': return CacheType.RoleRef
      case 'runs': return CacheType.RunRef
      case 'run-statuses': return CacheType.RunStatusRef
      case 'run-types': return CacheType.RunTypeRef
      case 'sort-sets': return CacheType.SortSetRef
      case 'status-disputes': return CacheType.StatusDisputeRef
      case 'templates': return CacheType.TemplateRef
      case 'topics': return CacheType.TopicRef
      case 'transaction-types': return CacheType.TransactionTypeRef
      case 'unapplied-cashes': return CacheType.UnappliedCashRef
      case 'users': return CacheType.UserRef
      case 'work-in-progress-items': return CacheType.WorkInProgressItemRef
      case 'work-item-priorities': return CacheType.WorkItemPriorityRef
      case 'work-item-templates': return CacheType.WorkItemTemplateRef
      case 'work-item-types': return CacheType.WorkItemTypeRef
      case 'work-items': return CacheType.WorkItemRef
      case 'work-queues': return CacheType.WorkQueueRef
      case 'workflows': return CacheType.WorkflowRef
      default:
        console.warn(`document-picker-list-item-ref missing cacheType ${typeString}`)
        return null
    }
  }
  Vue.prototype.$cacheTypeDetailFromString = (typeString) => {
    switch (typeString) {
      case 'account-contacts': return CacheType.UserDetail
      case 'accounts': return CacheType.AccountDetail
      // case 'aging-bucket-range-Detail': return CacheType.AgingBucketRangeDetail
      // case 'aging-buckets': return CacheType.AgingBucketDetail
      case 'aging-scenarios': return CacheType.AgingScenarioDetail
      case 'allocation-announcements': return CacheType.AllocationAnnouncementDetail
      // case 'announcement-origins': return CacheType.AnnouncementOriginDetail
      // case 'announcement-statuses': return CacheType.AnnouncementStatusDetail
      case 'business-divisions': return CacheType.BusinessDivisionDetail
      // case 'calendar-based-reminder-protocols': return CacheType.CalendarBasedReminderProtocolDetail
      // case 'calendar-based-reminder-statuses': return CacheType.CalendarBasedReminderStatusDetail
      // case 'calendar-based-reminders': return CacheType.CalendarBasedRemindersDetail
      // case 'collaboration-task-types': return CacheType.CollaborationTaskTypeDetail
      case 'collaboration-tasks': return CacheType.CollaborationDetail
      // case 'collection-segments': return CacheType.CollectionSegmentDetail
      case 'column-sets': return CacheType.ColumnSetDetail
      case 'columns': return CacheType.ColumnDetail
      // case 'contact-groups': return CacheType.ContactGroupDetail
      // case 'credit-hold-reasons': return CacheType.CreditHoldReasonDetail
      // case 'credit-statuses': return CacheType.CreditStatusDetail
      // case 'cultures': return CacheType.CultureDetail
      // case 'currencies': return CacheType.CurrencyDetail
      // case 'dispute-reason-categories': return CacheType.DisputeReasonCategoryDetail
      case 'dispute-reasons': return CacheType.DisputeReasonDetail
      case 'dispute-invoices': return CacheType.DisputeDetail
      case 'disputes': return CacheType.DisputeDetail
      // case 'dunning-reminder-statuses': return CacheType.DunningReminderStatusDetail
      // case 'dunning-reminders': return CacheType.DunningRemindersDetail
      // case 'dunning-runs': return CacheType.DunningRunDetail
      // case 'escalation-protocol-levels': return CacheType.EscalationProtocolLevelDetail
      case 'escalation-protocols': return CacheType.EscalationProtocolDetail
      // case 'fields': return CacheType.FieldDetail
      // case 'files': return CacheType.FileDetail
      // case 'group-contributors': return CacheType.GroupContributorDetail
      // case 'groups': return CacheType.GroupDetail
      case 'groupama-requests': return CacheType.GroupamaRequestDetail
      // case 'import-data-sources': return CacheType.ImportDataSourceDetail
      // case 'invoice-statuses': return CacheType.InvoiceStatusDetail
      case 'invoices': return CacheType.InvoiceDetail
      case 'label-groups': return CacheType.LabelGroupDetail
      case 'labels': return CacheType.LabelDetail
      case 'main-presets': return CacheType.MainPresetDetail
      // case 'messages-inbox': return CacheType.MessageInboxDetail
      // case 'messages-outbox': return CacheType.MessageOutboxDetail
      // case 'messages-unassigned': return CacheType.MessagesUnassignedDetail
      // case 'portal-payment-statuses': return CacheType.PortalPaymentStatusDetail
      // case 'portal-statuses': return CacheType.PortalStatusDetail
      // case 'promise-installments': return CacheType.PromiseInstallmentDetail
      // case 'promise-statuses': return CacheType.PromiseStatusDetail
      case 'promise-invoices': return CacheType.PromiseDetail
      case 'promises': return CacheType.PromiseDetail
      case 'providers': return CacheType.ProviderDetail
      // case 'relative-currencies': return CacheType.RelativeCurrencyDetail
      // case 'reminder-runs': return CacheType.ReminderRunDetail
      // case 'reminder-types': return CacheType.ReminderTypeDetail
      // case 'risk-categories': return CacheType.RiskCategoryDetail
      // case 'roles': return CacheType.RoleDetail
      // case 'runs': return CacheType.RunDetail
      case 'sort-sets': return CacheType.SortSetDetail
      // case 'status-disputes': return CacheType.StatusDisputeDetail
      // case 'templates': return CacheType.TemplateDetail
      case 'topics': return CacheType.TopicDetail
      // case 'transaction-types': return CacheType.TransactionTypeDetail
      // case 'unapplied-cashes': return CacheType.UnappliedCashDetail
      case 'users': return CacheType.UserDetail
      // case 'work-item-priorities': return CacheType.WorkItemPriorityDetail
      case 'work-item-templates': return CacheType.WorkItemTemplateDetail
      // case 'work-item-types': return CacheType.WorkItemTypeDetail
      // case 'work-items': return CacheType.WorkItemDetail
      case 'work-queues': return CacheType.WorkQueueDetail
      // case 'workflows': return CacheType.WorkflowDetail
      default:
        console.warn(`missing cacheType ${typeString}`)
        return null
    }
  }
  Vue.prototype.$cacheTypeToTranslatedTag = (ty) => {
    switch (ty) {
      // case CacheType.AccountContacts: return 't.AccountContacts'
      case CacheType.AccountDetail: return 't.Account'
      case CacheType.AccountRef: return 't.Accounts'
      case CacheType.AgingScenarioRef: return 't.AgingScenarios'
      case CacheType.AllocationAnnouncementRef: return 't.AllocationAnnouncements'
      case CacheType.AnnouncementOriginRef: return 't.AllocationOrigins'
      case CacheType.AnnouncementStatusRef: return 't.AllocationStatus'
      case CacheType.Ar24MessageStatusRef: return 't.Ar24MessageStatus'

      // case CacheType.AutomatedActivityTypeRef: return 't.AutomatedActivityTypes'
      case CacheType.BusinessDivisionDetail: return 't.BusinessDivision'
      case CacheType.BusinessDivisionRef: return 't.BusinessDivisions'
      case CacheType.CalendarBasedReminderProtocolRef: return 't.CalendarBasedReminderProtocols'
      case CacheType.CalendarBasedReminderStatusRef: return 't.CalendarBasedReminderStatuses'
      case CacheType.CalendarBasedRemindersRef: return 't.CalendarBasedReminders'
      case CacheType.CollaborationTaskRef: return 't.CollaborationTasks'
      case CacheType.CollaborationTaskTypeRef: return 't.CollaborationTaskTypes'
      case CacheType.CollectionSegmentRef: return 't.CollectionSegments'
      case CacheType.ColumnRef: return 't.Columns'
      case CacheType.ColumnSetRef: return 't.ColumnSets'
      case CacheType.ContactGroupRef: return 't.ContactGroups'
      case CacheType.CreditHoldReasonRef: return 't.CreditHoldReason'
      case CacheType.CreditStatusGroupamaRef: return 't.CreditStatusGroupama'
      case CacheType.CreditStatusRef: return 't.CreditStatus'
      case CacheType.CultureRef: return 't.Culture'
      case CacheType.CurrencyRef: return 't.Currency'
      case CacheType.DisputeInvoiceRef: return 't.DisputeInvoices'
      case CacheType.DisputeReasonCategoryRef: return 't.DisputeReasonCategories'
      case CacheType.DisputeReasonRef: return 't.DisputeReasons'
      case CacheType.DisputeRef: return 't.Disputes'
      case CacheType.DunningReminderStatusRef: return 't.DunningReminderStatuses'
      case CacheType.DunningRemindersRef: return 't.DunningReminders'
      case CacheType.DunningRunRef: return 't.DunningRuns'
      case CacheType.DocumentTypeRef: return 't.DocumentTypes'
      case CacheType.EscalationProtocolLevelRef: return 't.EscalationLevels'
      case CacheType.EscalationProtocolRef: return 't.EscalationProtocols'
      case CacheType.FieldRef: return 't.Fields'
      case CacheType.FileRef: return 't.Files'
      case CacheType.GroupamaRequestRef: return 't.GroupamaRequests'
      case CacheType.GroupamaDecisionCodeRef: return 't.GroupamaDecisionCodes'
      case CacheType.GroupContributorRef: return 't.GroupContributors'
      case CacheType.GroupRef: return 't.Groups'
      case CacheType.ImportDataSourceRef: return 't.ImportDataSources'
      case CacheType.InvoiceRef: return 't.Invoices'
      case CacheType.InvoiceStatusRef: return 't.InvoiceStatuses'
      case CacheType.LabelGroupRef: return 't.LabelGroups'
      case CacheType.LabelRef: return 't.Labels'
      case CacheType.MailevaLetterStatusRef: return 't.MailevaLetterStatus'
      case CacheType.MessageInboxRef: return 't.MessagesInbox'
      case CacheType.MessagePriorityRef: return 't.Priority'
      case CacheType.MessageOutboxRef: return 't.MessagesOutbox'
      case CacheType.MessagesUnassignedRef: return 't.MessagesUnassigned'
      case CacheType.PortalPaymentStatusRef: return 't.PortalPaymentStatus'
      case CacheType.PortalStatusRef: return 't.PortalStatus'
      case CacheType.ProcessRef: return 't.Processes'
      case CacheType.PromiseInstallmentRef: return 't.Installment'
      case CacheType.PromiseRef: return 't.Promises'
      case CacheType.PromiseStatusRef: return 't.PromiseStatuses'
      case CacheType.PromiseInvoiceRef: return 't.PromiseInvoices'
      case CacheType.ProviderRef: return 't.Providers'
      case CacheType.ProviderTypeRef: return 't.ProviderTypes'
      case CacheType.RelativeCurrencyRef: return 't.RelativeCurrencies'
      case CacheType.ReminderRunRef: return 't.ReminderRuns'
      case CacheType.ReminderTypeRef: return 't.ReminderTypes'
      case CacheType.RiskCategoryRef: return 't.RiskCategories'
      case CacheType.RoleRef: return 't.Roles'
      case CacheType.RunRef: return 't.Runs'
      case CacheType.RunStatusRef: return 't.RunStatuses'
      case CacheType.RunTypeRef: return 't.RunTypes'
      case CacheType.SortSetRef: return 't.SortSets'
      case CacheType.StatusDisputeRef: return 't.StatusDisputes'
      case CacheType.TopicRef: return 't.Topics'
      case CacheType.TransactionTypeRef: return 't.TransactionTypes'
      case CacheType.UnappliedCashRef: return 't.UnappliedCashes'
      case CacheType.UserRef: return 't.Users'
      case CacheType.WorkInProgressItemRef: return 't.WorkInProgressItems'
      case CacheType.WorkItemPriorityRef: return 't.WorkItemPriorities'
      case CacheType.WorkItemRef: return 't.WorkItems'
      case CacheType.WorkItemTemplateRef: return 't.WorkItemTemplates'
      case CacheType.WorkItemTypeRef: return 't.WorkItemTypes'
      case CacheType.WorkQueueRef: return 't.WorkQueues'
      case CacheType.WorkflowRef: return 't.Workflows'
      default: console.warn(`document-picker-list-item-ref missing translation for CacheType.${CacheType[ty]}`)
        return null
    }
  }
  Vue.prototype.$documentNameFromString = (typeString) => Vue.prototype.$cacheTypeToTranslatedTag(Vue.prototype.$cacheTypeFromString(typeString))
})()

Vue.use(CKEditor)
// Vue.use(VueTween)
Vue.use(VueKeepScrollPosition)
Vue.use(Account)
Vue.use(Http, { store })

Vue.prototype.uuid = uuid

const VueInstance = new Vue({
  vuetify,
  i18n,
  // @ts-ignore
  iconsService,
  router,
  store,
  utils,
  render: h => h(App)
})
VueInstance.$mount('#app')

export default VueInstance
