import Vue from 'vue'
import Http from '@/plugins/http'

export default new Vue({
  Http,
  data () {
    return {
      alreadyFetched: false,
      authenticationMode: 'builtin',
      ssoInfo: null,
      outsider: window.localStorage.getItem('outsider') ?? false
    }
  },
  methods: {
    async initialFetch () {
      if (!this.alreadyFetched) {
        await this.fetchData()
        this.alreadyFetched = true
      }
    },
    async fetchData () {
      const results = await this.$http().get('/core/authentication/mode')

      this.authenticationMode = results.data.mode.toLowerCase()

      if (this.isExternal()) {
        this.ssoInfo = (await this.$http().get('/core/sso/info')).data
      }
    },
    storeProvider (provider) {
      if (provider) {
        window.localStorage.setItem('ssoProvider', JSON.stringify(provider))
      } else {
        window.localStorage.removeItem('ssoProvider')
      }
    },
    getStoredProvider () {
      if (!this.ssoInfo) {
        return null
      }

      const stored = window.localStorage.getItem('ssoProvider')

      if (stored) {
        const provider = JSON.parse(stored)

        if (!this.ssoInfo.internalProviders.some((p) => p.id === provider.id)) {
          this.storeProvider(null)
          return null
        }
        return provider
      }

      return null
    },
    setOutsider (outsider) {
      window.localStorage.setItem('outsider', JSON.stringify(outsider))
      this.outsider = outsider
    },
    isOutsider () {
      return this.outsider
    },
    isExternal () {
      return this.authenticationMode !== 'builtin'
    },
    redirectExternalLoginIfPossible (provider, redirection) {
      if (this.isExternal()) {
        const redirect = (redirection !== undefined ? `?redirect=${encodeURIComponent(redirection)}` : '')

        provider = provider ?? this.getDefaultProvider()

        window.location.href = new URL(`/api/core/sso/begin/${provider.id}${redirect}`, window.location.origin)
      }
    },
    needExternalSelection () {
      if (this.getStoredProvider() !== null) {
        return false
      }

      if (this.ssoInfo) {
        return this.ssoInfo.internalProviders.length !== 1
      }
      return false
    },
    getDefaultProvider () {
      return this.getStoredProvider() ?? this.getSsoProviders()[0]
    },
    getAuthMode () {
      return this.authenticationMode
    },
    getSsoProviders () {
      return this.ssoInfo?.internalProviders || []
    },
    getExternSsoProviders () {
      return this.ssoInfo?.publicProviders || []
    },
    sendToLogin (router) {
      if (this.isExternal()) {
        // FIXME: WTF WHY DOES IT GOES IN EXTERN SOMETIMES
        // router.push('/externalLoginProviderSelection' + (this.isOutsider() ? '/extern' : ''))
        router.push('/externalLoginProviderSelection')
      } else {
        router.push('/login')
      }
    }
  }
})
