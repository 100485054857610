import Vue from 'vue'

function initState () {
  return {
    tab: 'account-infos'
  }
}

function getStateById (map, accountId) {
  let state = map.get(accountId)

  if (!state) {
    state = Vue.observable(initState())
    map.set(accountId, state)
  }

  return state
}

class AccountClass {
  constructor (vue) {
    this._map = new Map()
    this._vue = vue
  }

  navigate (accountId, options) {
    Object.assign(getStateById(this._map, accountId), initState(), this.lodash.cloneDeep(options))

    const path = '/accounts/' + accountId

    if (this._vue.$route.path !== path) {
      this._vue.$router.push({ path }).catch(_ => { })
    }
  }

  get state () {
    if (this._vue.$route.name === 'account') {
      return getStateById(this._map, this._vue.$route.params.id)
    }
    return null
  }
}

export default {
  install: function (vue) {
    let account = null
    Object.defineProperty(Vue.prototype, '$account', {
      get: function () {
        if (!account) {
          account = new AccountClass(this)
        }
        return account
      }
    })
  }
}
