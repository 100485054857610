import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import Collection from '@/icons/svg/collection'
import Groupama from '@/icons/svg/groupama'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#124669',
        secondary: '#237bb5',
        accent: '#9c27b0',
        error: '#f44336',
        warning: '#ffc107',
        info: '#009688',
        success: '#4caf50'
      },
      dark: {
        primary: '#124669',
        secondary: '#237bb5',
        accent: '#9c27b0',
        error: '#f44336',
        warning: '#ffc107',
        info: '#009688',
        success: '#4caf50'
      }
    }
  },
  icons: {
    values: {
      'cot-collection': {
        component: Collection
      },
      groupama: {
        component: Groupama
      }
    }
  }
}

export default new Vuetify(opts)
