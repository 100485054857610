<template>
  <v-app class="cot">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created () {
    window.addEventListener('keydown', this.searchOnKeys)
  },
  destroy () {
    window.removeEventListener('keydown', this.searchOnKeys)
  },
  methods: {
    searchOnKeys (e) {
      if (e.key === 'F3' || (e.ctrlKey && e.key === 'F')) {
        document.getElementById('search-field').focus()
        e.preventDefault()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.fgRed
  color red !important

.fgDue
  color orange

.cot
  .theme--light.v-card
    box-shadow 0px 0px 15px 0px rgba(0, 0, 0, 0.1) !important
    border-radius 15px

  .theme--light.v-list, .theme--light.v-data-table
    background-color transparent

  .theme--light.v-btn-toggle:not(.v-btn-toggle--group)
    background-color transparent

  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):not(.v-btn--depressed)
    background-color transparent

  .theme--light.v-stepper
    background-color transparent
    box-shadow none

  .theme--light.v-data-table tbody tr:hover:not(.v-data-table__expanded__content)
    background #FFFFFF33

  .theme--dark.v-list, .theme--dark.v-data-table
    background-color transparent

  .theme--dark.v-btn-toggle:not(.v-btn-toggle--group)
    background-color transparent

  .theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):not(.v-btn--depressed)
    background-color transparent

  .theme--dark.v-stepper
    background-color transparent
    box-shadow none

  .theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expanded__content)
    background #FFFFFF33

.v-menu__content.theme--dark.menuable__content__active
  background-color #424242

.v-menu__content.theme--light.menuable__content__active
  background-color #FFFFFF
</style>

<style lang="stylus">
/* Styling Firefox */
#app, #app *
  scrollbar-width thin

#app.theme--light, #app.theme--light *
  scrollbar-color #767676 #dadada

/* Styling Webkit */
::-webkit-scrollbar
  width 8px
  height 8px

#app.theme--dark
  ::-webkit-scrollbar-corner, ::-webkit-scrollbar-track
    background #1e1e1f

  ::-webkit-scrollbar-thumb
    background #767677

#app.theme--light
  ::-webkit-scrollbar-corner, ::-webkit-scrollbar-track
    background #dadada

  ::-webkit-scrollbar-thumb
    background #767676

// Colors
@css {
  .primary, .primary * {
    color: hsl(0, 0%, var(--primary-lightness))
  }

  .v-badge__badge {
    color: hsl(0, 0%, var(--primary-lightness)) !important
  }

/* .v-btn */
  /*.cke_reset,*/ .v-select, .chat-header, .chat, .documents-by-type, .v-dialog, .v-bottom-sheet, .v-chip, .document-toolbar-row, .document-tabs, .document-tabs-filter, .input-text {
    border-radius: var(--border-radius) !important
  }

  .chat-header, .v-bottom-sheet {
    border-radius: var(--border-radius-top) !important
  }

  .v-btn-toggle--rounded {
      border-radius: var(--border-radius) !important;
  }

  .primary .v-btn,
.primary.v-btn,
.primary.v-btn .v-btn__content,
.primary.v-tab--active,
.primary.v-card>.v-card__text,
.primary[role="button"] .v-select__selection {
      color: hsl(0, 0%, var(--primary-lightness))
    }

  .primary[role="button"] .v-input__icon--append .v-icon,
.primary>.v-icon,
.primary.v-pagination__item {
    color: hsl(0, 0%, var(--primary-lightness)) !important
  }

  .secondary, .secondary *, .secondary:not(.chat-child-message) {
    color: hsl(0, 0%, var(--secondary-lightness))
  }

  .secondary .v-btn,
.secondary .v-btn .v-btn__content,
.secondary.v-btn,
.secondary.v-btn .v-btn__content,
.secondary.v-tab--active,
.secondary.v-card>.v-card__text,
.secondary[role="button"] .v-select__selection,
.secondary[role="button"] .v-input__icon--append .v-icon,
.secondary>.v-icon {
    color: hsl(0, 0%, var(--secondary-lightness)) !important
  }

  .accent, .accent * {
    color: hsl(0, 0%, var(--accent-lightness))
  }

  .accent .v-btn,
.accent .v-btn .v-btn__content,
.accent.v-btn,
.accent.v-btn .v-btn__content,
.accent.v-tab--active,
.accent.v-card>.v-card__text,
.accent[role="button"] .v-select__selection,
.accent[role="button"] .v-input__icon--append .v-icon,
.accent>.v-icon  {
    color: hsl(0, 0%, var(--accent-lightness)) !important
  }
}

// Header's shadow
header
  box-shadow 0 0 0.5rem rgba(8, 21, 66, 0.1) !important

// Hide arrow in input number
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
  -webkit-appearance none
  margin 0

/* Firefox */
input[type=number]
  -moz-appearance textfield

// Force a plain background on an item
.background-plain, .v-dialog, .quick-chat-text
  background var(--bg-plain) !important

// Force a translucid background on an item
.background-translucid, :not(.container)>.v-card:not(.bar):not(.size-card):not(.background-plain):not(.primary):not(.secondary):not(.accent):not(.error), .v-list.background-translucid
  background-color var(--bg-transparency) !important

// Remove background color on v-cards inside a container (mainly bottom sheets)
.container>.v-card
  background-color unset

// Override selected v-list-item color to prevent primary color messing with the list background
.v-list-item.primary--text
  color inherit !important

.v-list
  background transparent !important

.v-list.v-select-list
  background var(--bg-transparency) !important

// Make data tables container transparent
.v-data-table
  background transparent !important

  .v-data-table-header th
    font-size 0.875em !important
    font-weight bold !important

  td
    padding-top 16px
    padding-bottom 16px

// No text shadow in chips
.v-chip
  text-shadow initial

// Fix v-select width
.v-select .v-select__selection
  min-width min-content

.v-select .v-select__selections>input
  width 0

.drag-handle, .drag-handle *
  cursor move

.clickable, .clickable *
  cursor pointer
  user-select none !important

.default-cursor
  cursor default

.v-card, .v-menu__content, .border-radius, .border-radius::before
  border-radius var(--border-radius) !important

.border-radius-left
  border-radius var(--border-radius) 0 0 var(--border-radius) !important

.border-radius-right
  border-radius 0 var(--border-radius) var(--border-radius) 0 !important

.border-radius-top
  border-radius var(--border-radius) var(--border-radius) 0 0 !important

.footer-info
  font-size 0.8em

// Prevent apexcharts icons to overlap with dropdowns
.apexcharts-toolbar
  z-index 1 !important

// Bar graph
.svg-icon
  display inline-block
  width 16px
  height 16px
  color inherit
  vertical-align middle
  fill none
  stroke currentColor

.svg-fill
  fill currentColor
  stroke none

.svg-up
  // default
  transform rotate(0deg)

.svg-right
  transform rotate(90deg)

.svg-down
  transform rotate(180deg)

.svg-left
  transform rotate(-90deg)

// border bottom des champs readonly
.v-input.v-text-field.v-input--is-readonly .v-input__slot::before
  border-image repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat !important

.v-input.v-text-field.v-input--is-readonly .v-input__slot::after
  border none !important

.nowrap
  white-space nowrap

// Add margin to v-card, allowing to use them without v-container, v-row and v-col
:not(.v-menu__content):not(.v-dialog)>.v-card
  margin 0 0.5em 0.25em

// Changing default shadows fo v-card and search
.theme--light.v-card, .document-tabs
  box-shadow 0 0.5rem 1rem rgba(8, 21, 66, 0.1) !important

.bordered-item
  border solid 1px var(--bg-plain) !important

.maxW200px
  max-width 200px

.cke_inner
  background none repeat scroll 0 0 transparent

  .cke_top
    border none
    background none repeat scroll 0 0 transparent
    box-shadow none
    padding 0

    .cke_combo_button
      margin 0 5px 0 0

    .cke_toolgroup
      margin 0 0 0 15px

// Force v-tab icons to match the text color in dark mode
main .v-tab--active .v-icon.theme--light
  color rgba(0, 0, 0, 0.87) !important

// Display the "move" cursor for draggable items
.draggable
  cursor move

// Allow text selection in tabs
.v-tab
  user-select auto !important

/* This is for pagination */
.v-pagination__item, .v-pagination__navigation
  background transparent !important
  box-shadow unset !important
  border-radius 5px !important
  font-size 0.875rem !important
  height 20px !important
  min-width 20px !important

.theme--light.v-pagination .v-pagination__item--active
  /* background #E5E5E5 !important */
  /* color unset !important */

.theme--dark.v-pagination
  .v-pagination__item--active
    /* background #222222 !important */

  .theme--dark.v-pagination .v-pagination__item
    color #FFFFFF !important

/*  */
.v-data-table tbody tr.v-data-table__selected
  background-color var(--primary-color-10) !important

/* CSS animations */
.rotate-180
  transform rotate(180deg)
  transition transform 200ms ease

.nudge-element
  animation nudge-element 0.4s linear

@keyframes nudge-element
  0%
    transform rotate(-10deg)

  33%
    transform rotate(10deg)

  66%
    transform rotate(-10deg)

.pulse-element
  animation pulse-element 1s linear

@keyframes pulse-element
  0%
    transform scale(1)
    color var(--primary-color)

  25%
    transform scale(2)
    color var(--primary-color)

  50%
    transform scale(1)
    color var(--primary-color)

  75%
    transform scale(2)
    color var(--primary-color)

.pulse-element-loop
  animation pulse-animation 2s infinite
  border-radius 50%

@keyframes pulse-animation
  0%
    box-shadow 0 0 0 0px rgba(0, 0, 0, 0.2)

  100%
    box-shadow 0 0 0 20px rgba(0, 0, 0, 0)
</style>

<!-- Transition animations -->
<style lang="stylus">
// slide-x used in search for document type tabs
.slide-x-enter-active, .slide-x-leave-active, .slide-x-move, .fade-enter-active, .fade-leave-active
  transition all 0.4s

.slide-x-enter, .slide-x-leave-to
  opacity 0
  transform translateX(30px)

.fade-enter, .fade-leave-to
  opacity 0

.gap
  gap 0.5em

.ref-detail
  line-height 1.1
  font-size 12px
  opacity 0.6
  white-space nowrap

.shadow-text
  opacity 0.6

.text-transform-none
  text-transform none
  font-size 14px

.grecaptcha-badge
  visibility hidden
</style>
