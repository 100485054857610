import Vue from 'vue'
import Vuex from 'vuex'

import currentUser from './modules/currentUser'
import dragAndDrop from './modules/dragAndDrop'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { currentUser, dragAndDrop },
  state: {
    snackbar: {
      callback: undefined,
      callbackMessage: '',
      color: 'info',
      icon: null,
      message: null,
      visible: false
    },
    module: { name: 't.Dashboard' }
  },
  mutations: {
    closeSnackbar (state) {
      state.snackbar = Object.assign({}, state.snackbar, { visible: false, callback: undefined })
    },
    showSnackbar (state, _) {
      _.visible = true
      _.timeout = _.callback ? 10000 : 5000
      Vue.nextTick(() => { state.snackbar = Object.assign({}, state.snackbar, _) })
    },
    setModule (state, _) {
      state.module = _
    }
  },
  actions: {
    closeSnackbar ({ commit }) {
      commit('closeSnackbar')
    },
    showInformationSnackbar ({ commit }, message) {
      commit('closeSnackbar')
      commit('showSnackbar', Object.assign({}, {
        color: 'info',
        icon: this._vm.$icon('i.Information'),
        message
      }))
    },
    showSuccessSnackbar ({ commit }, params) {
      let callback
      let callbackMessage
      let message
      if (typeof params === 'string') {
        message = params
      } else {
        ({ message, callback, callbackMessage } = params)
      }
      commit('closeSnackbar')
      commit('showSnackbar', Object.assign({}, {
        callback,
        callbackMessage,
        color: 'success',
        icon: this._vm.$icon('i.Checked'),
        message
      }))
    },
    showWarningSnackbar ({ commit }, message) {
      commit('closeSnackbar')
      commit('showSnackbar', Object.assign({}, {
        callback: undefined,
        callbackMessage: undefined,
        color: 'warning',
        icon: this._vm.$icon('i.AlertOctagon'),
        message
      }))
    },
    showErrorSnackbar ({ commit }, message) {
      commit('closeSnackbar')
      commit('showSnackbar', Object.assign({}, {
        callback: undefined,
        callbackMessage: undefined,
        color: 'error',
        icon: this._vm.$icon('i.Error'),
        message
      }))
    }
  },
  getters: {
    snackbar: state => {
      return state.snackbar
    },

    module: state => {
      return state.module
    }
  }
})

export default store
