import Vue from 'vue'

/**
   * Creates a function that deduplicate the call with instance.$nextTick().
   * @param {*} instance - component instance
   * @param {*} handler - function to call on nextTick
   * @returns A function to trigger the call.
   */
Vue.prototype.$nextTickDedup = function (handler) {
  let trig = false
  const inst = this

  return function () {
    if (!trig) {
      trig = true

      inst.$nextTick(function () {
        trig = false
        handler.call(inst)
      })
    }
  }
}

Vue.prototype.isNumeric = function (str) {
  if (typeof str !== 'string') return false
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export default {}
