import Vue from 'vue'
import Http from '@/plugins/http'
import router from '@/router'

export default new Vue({
  Http,
  router,
  data () {
    return {
      valid: undefined
    }
  },
  methods: {
    async checkLicense () {
      if (!this.valid) {
        const license = await this.getLicenseInfo()
        this.valid = license && license?.valid && !license?.expired
      }
    },
    async getLicenseInfo () {
      try {
        return (
          await this.$http()
            .get('/core/v6/settings/license', {
              validateStatus: (status) => {
                if (status === 401) {
                  return false
                }
                return status >= 200 && status < 300
              }
            })
        ).data
      } catch (e) {
        if (e.response.status === 401) {
          return { skip: true }
        }
      }
    },
    resetLicenseStatus () {
      this.valid = undefined
    }
  }
})
