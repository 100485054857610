import Vue from 'vue'
import icons from '@/icons/icons.json'

const plugin = {
  /**
   * @param {typeof Vue} Vue
   * @returns {void}
   */
  install (Vue) {
    Vue.prototype.$icon = function (id) {
      const r = icons[id] ?? 'mdi-head-question'
      return r
    }

    Vue.prototype.getIcons = function () {
      const r = []
      Object.entries(icons).forEach(_ => {
        r.push({
          id: _[0],
          value: _[1]
        })
      })

      return r
    }
  }
}

Vue.use(plugin)
export default plugin
