const dragAndDrop = {
  state: () => ({
    dragTargetItem: undefined,
    dragstartEvent: undefined
  }),
  getters: {
    dragTargetItem: state => state.dragTargetItem,
    dragstartEvent: state => state.dragstartEvent
  },
  mutations: {
    setDragTargetItem (state, _) {
      state.dragTargetItem = _
    },
    setDragstartEvent (state, _) {
      state.dragstartEvent = _
    }
  }
}

export default dragAndDrop
